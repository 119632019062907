import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[20px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/banderas%2Fsatisfacion.png?alt=media&token=7811692f-a94e-4f76-945e-8b55e6a36438" alt="satisfaction" className="w-52 h-auto object-contain mx-auto"/>
                            <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                            <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                            <div className="flex justify-center items-center gap-5">
                                <ButtonContent btnStyle="three" />
                                <a href="https://www.enhancify.com/demo" target="_blank" rel="noopener noreferrer" class="inline-block px-8 py-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                                    Get Financing
                                </a>

                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;